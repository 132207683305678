import React from 'react';
import { Link } from 'gatsby';
import backgroundImage from '../../assets/image/cta/ocorie-ft-img.jpg';

const CTA = ({ className, ...rest }) => {
  return (
    <>
      <div
        className={className}
        {...rest}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-10 col-md-11">
              <div
                className="text-center pt-14 pb-14 py-lg-22 dark-mode-texts"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <h2 className="font-size-10 mb-7">
                  Apply now to join the community of Advanced Information
                  Security Experts
                </h2>
                <Link
                  to={'/contact#contact-form'}
                  className="btn btn-dodger-blue-2 rounded-5 mt-12"
                >
                  Apply Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CTA;
